.section--band {
  &__bg {
    width: 100%;
    height: auto;
    margin-top: -20%;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  &__block {
    padding: sp(9);
    margin-top: $header-height - sp(9);

    @include screen(sm) {
      padding: sp(12);
    }

    @include screen(lg) {
      max-width: max-width(2xl);
    }

    &--right {
      float: right;
      clear: right;
    }
  }

  @include screen-until(sm) {
    .section__container {
      padding: 0;
    }
  }

  @include screen(lg) {
    .section__container {
      padding: 0 17vw 0 39vw;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 30vw;
      max-width: none;
      z-index: 10;
    }

    .section__block {
      margin-left: auto;
      margin-right: auto;
    }

    &__bg {
      margin-top: -30.3%;
    }

    &__wrapper {
      min-height: 50vw;
      max-height: 100vh;
    }
  }
}
