.section--concerts {
  @extend %non-prose;

  h2 {
    img {
      transform: translateY(0.2em);
      display: inline;
    }
  }

  .calendar {
    &__item {
      @extend .section__container;
    }
  }

  background-color: c(gray, 300);
  color: black;
}

.songkick-widget {
  margin: 0 -10px;
}

.calendar {
  display: flex;
  flex-direction: column;

  a {
    display: block;

    &:hover {
      background-color: black;
      color: c(gray, 300);
    }
  }

  &__month {
    font-size: em(font-size(sm));
  }

  &__day {
    font-size: em(font-size(2xl));
    font-weight: font-weight(black);
  }

  &__year {
    font-size: em(font-size(xs));
  }

  &__item {
    display: flex;
    align-items: center;
    padding: em(sp(4)) 0;

    &__time {
      display: flex;
      flex-direction: column;
      margin-right: em(sp(4));
      justify-content: center;
      align-items: center;
    }

    &__info {
      display: flex;
      flex-direction: column;

      h3 {
        text-transform: uppercase;
        font-size: em(font-size(xl));
        margin: 0;
        margin-bottom: em(sp(1));
      }

      span {
        font-size: font-size(base);
      }
    }
  }

  @include screen(md, font-size, font-size(lg));
  @include screen(lg, font-size, font-size(xl));
}

ul.calendar {
  list-style: none;
}
