.section--main {
  min-height: 100vh;

  &__play {
    opacity: 90%;
    @extend %icon-btn;
    width: sp(40);
    height: sp(40);
  }

  &__chevron {
    opacity: 90%;
    @extend %icon-btn;
    width: sp(28);
    height: sp(28);
  }
}

.header-placeholder {
  height: $header-height;
  z-index: 20;
}
