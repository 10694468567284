.header {
  @extend %non-prose;
  @include screen(sm, position, fixed);
  position: absolute;
  top: 0;
  width: 100%;
  height: $header-height;
  display: flex;
  z-index: 20;
  color: c(gray-50);
  background-color: rgba(0, 0, 0, 0.7);
  font-size: font-size(lg);
  user-select: none;
  @include screen(lg, font-size, font-size(base));
  @include screen(xl, font-size, font-size(lg));
  @include screen(2xl, font-size, font-size(xl));

  @include cond-backdrop(blur(15px)) {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__flag {
    font-size: em(font-size(2xl));
    padding: sp(2);
  }

  &__container {
    @include container(
      $viewport-edge-padding,
      (
        "lg": 0,
      )
    );
  }

  &__top {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    height: $header-height;
  }

  &__top,
  &__container,
  &__nav {
    align-items: center;
  }

  h1 {
    font-size: em(font-size(xl));
    font-weight: font-weight(black);
    letter-spacing: letter-spacing(tight);
    margin-right: sp(1);
  }

  &__nav {
    display: none;
    flex-grow: 1;
    font-weight: font-weight(light);
    font-size: em(font-size(lg));
    color: c(gray, 300);
  }

  &__links {
    display: flex;

    flex-grow: 1;
    align-items: stretch;
    margin-right: sp(3);

    li {
      list-style: none;
    }

    a {
      margin: 0 sp(2);
    }
  }

  &__link a {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: transparent;
  }

  &__link--active a {
    border-color: c(gray, 300);
    //color: c(white);
  }

  .icon-nav {
    font-size: em(font-size(xl));
  }

  @include screen-until(lg) {
    position: fixed;
    overflow: hidden;
    transition: height 300ms ease-out;

    .header__nav,
    .header__links,
    .header__links li {
      flex-direction: column;
    }

    .header__nav {
      display: flex;
      top: $header-height;
      font-size: font-size(4xl);
      align-items: stretch;
    }

    .header__top {
      width: 100%;
    }

    .header__links {
      li {
        display: flex;
        justify-content: stretch;
      }

      a {
        margin: sp(2) 0;
        line-height: em(font-size(base));
      }

      margin: em(sp(2)) 0 em(sp(4));
    }

    .header__link a {
      border-bottom-width: sp(0.5);
    }

    will-change: height;
    &--open {
      height: 100%;
    }

    &__fallback-blur {
      filter: blur(15px);
      @include on-backdrop(blur(15px)) {
        filter: none;
      }
    }
  }

  @include screen(lg) {
    .hamburger {
      display: none;
    }

    .icon-nav {
      margin: 0;
    }

    &__container,
    &__nav {
      display: flex;
    }
  }
}
