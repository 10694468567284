@use "sass:math";
@use "sass:meta";
@use "sass:map";
@use "variables" as *;

@mixin pointer {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin on-backdrop($filter) {
  @supports ((-webkit-backdrop-filter: $filter) or (backdrop-filter: $filter)) {
    @content;
  }
}

@mixin backdrop($filter) {
  -webkit-backdrop-filter: $filter;
  backdrop-filter: $filter;
}

@mixin cond-backdrop($filter) {
  @include on-backdrop($filter) {
    @include backdrop($filter);
    @content;
  }
}

@mixin container($pad: 0, $pads: ()) {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  padding-left: $pad;
  padding-right: $pad;

  $pad-active: false;
  @each $screen, $width in $screen-map {
    @if map.has-key($pads, $screen) {
      $pad-active: true;
      $pad: map.get($pads, $screen);
    }

    @include screen($screen) {
      @if math.compatible($width, $pad) {
        max-width: $width - 1px + 2 * $pad;
      } @else {
        max-width: calc(#{$width - 1px} + #{2 * $pad});
      }

      @if $pad-active == true {
        padding-left: $pad;
        padding-right: $pad;

        $pad-active: false;
      }
    }
  }
}
