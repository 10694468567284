.section--music {
  .section__content {
    @extend .grid;

    .album {
      width: 50%;

      @include screen-until(lg) {
        &--placeholder:nth-of-type(odd) {
          width: 100%;
          .album__img {
            height: 50vw;
          }
        }
      }

      @include screen(lg) {
        @include width(100%, 3);

        &--placeholder:nth-of-type(3n + 1) {
          width: 100%;
          .album__img {
            @include height(100vw, 3);
          }
        }

        &--placeholder:nth-of-type(3n + 2) {
          @include width(200%, 3);
          .album__img {
            @include height(100vw, 3);
          }
        }
      }

      &__info {
        @include screen(sm, font-size, em(font-size(xl)));
        @include screen(md, font-size, em(font-size(2xl)));
        @include screen(lg, font-size, em(font-size(xl)));
        @include screen(xl, font-size, em(font-size(2xl)));
        @include screen(2xl, font-size, em(font-size(3xl)));
      }
    }
  }
}

.album {
  @extend %non-prose;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__img {
    width: 100%;
    height: auto;
    transition: filter 0.1s ease-in;
    will-change: filter;
    display: block;
    object-fit: cover;
    object-position: center center;
  }

  &__info {
    position: relative;
    text-transform: uppercase;
    transition: opacity 0.1s ease-in;
    flex-grow: 1;
    overflow: hidden;

    h3 {
      font-weight: font-weight(black);
      font-size: em(font-size(xl));
      margin-top: 0;
    }

    .icon-nav {
      @extend %icon-nav--black;
      font-size: em(font-size(4xl));
    }

    &__overlay {
      position: relative;
      background-color: color.change(c(gray, 100), $alpha: 0.5);
      color: black;
      padding: em(sp(4));
      display: flex;
      align-items: stretch;
      justify-content: start;

      @include backdrop(blur(15px));

      @extend .full;

      &__inner {
        & > * {
          margin-bottom: em(sp(2));

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      h3 {
        flex-grow: 1;
      }
    }

    &__backdrop {
      @extend .stack;
      @extend .full;
      object-position: 50% 50%;
      object-fit: cover;
      filter: blur(15px);
      transform: scale(1.5);

      @include on-backdrop(blur(15px)) {
        filter: none;
      }
    }
  }

  &--placeholder {
    .album__info {
      .icon-nav {
        font-size: em(font-size(4xl));
      }

      &__overlay {
        justify-content: center;
        align-items: center;
      }
    }
  }

  @include pointer {
    &__info {
      @include stack;
      width: 100%;
      height: 100%;
      opacity: 0;

      &__overlay {
        justify-content: center;
        align-items: center;

        h3 {
          flex-grow: 0;
        }
      }

      &__backdrop {
        display: none;
      }
    }

    .album__info {
      .icon-nav {
        font-size: em(font-size(2xl));
      }
    }

    &--placeholder {
      .album__info {
        .icon-nav {
          font-size: em(font-size(3xl));
        }
      }
    }

    &:hover {
      .album__info {
        display: block;
        opacity: 1;
      }

      &__img {
        filter: blur(15px);

        @include on-backdrop(blur(15px)) {
          filter: none;
        }
      }
    }
  }
}
