@use "sass:math";
@use "../utils/variables" as *;

.icon-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  margin: -#{em(sp(1.5))};

  li {
    @extend %icon-btn;
    padding: em(sp(1.5));
    list-style: none;
  }

  &--pad {
    margin: 0;
  }

  img {
    height: 0.5em;
    width: auto;
    transform: translateY(12%);
  }
}

%icon-nav--black {
  li {
    @extend %icon-btn--black;
  }
}

%icon-btn {
  color: c(gray, 300);

  &:hover {
    color: c(gray, 100);
  }

  &:focus {
    color: c(white);
  }

  @at-root {
    button#{&} {
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}

%icon-btn--black {
  color: black;
  &:hover {
    color: c(gray, 800);
  }

  &:focus {
    color: c(gray, 700);
  }
}

.songkick-logo {
  transform: translateY(28%);
}
