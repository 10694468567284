@use "utils";
@use "sass:math";

$-font-map: (
  "sans": (
    "Cantarell",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    "Helvetica Neue",
    sans-serif,
  ),
  "serif": (
    ui-serif,
    Georgia,
    Cambria,
    "Times New Roman",
    Times,
    serif,
  ),
  "mono": (
    ui-monospace,
    SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    "Liberation Mono",
    "Courier New",
    monospace,
  ),
);

$fa-fw-width: math.div(20em, 16);

@function font($name...) {
  @return utils.property-get($-font-map, $name);
}

$screen-map: (
  "sm": 640px,
  "md": 768px,
  "lg": 1024px,
  "xl": 1280px,
  "2xl": 1536px,
);

@function screen($name...) {
  @return utils.property-get($screen-map, $name);
}

@mixin screen($name, $key: none, $value: none) {
  @media (min-width: screen($name)) {
    @if $key != none {
      #{$key}: $value;
    }
    @content;
  }
}

@mixin screen-until($name, $key: none, $value: none) {
  @media (max-width: screen($name) - 1px) {
    @if $key != none {
      #{$key}: $value;
    }
    @content;
  }
}

$-letter-spacing-map: (
  "tighter": -0.05em,
  "tight": -0.025em,
  "normal": 0em,
  "wide": 0.025em,
  "wider": 0.05em,
  "widest": 0.1em,
);

@function letter-spacing($name...) {
  @return utils.property-get($-letter-spacing-map, $name);
}

$-shadow-map: (
  "sm": (
    0 1px 2px 0 rgba(0, 0, 0, 0.05),
  ),
  "default": (
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06),
  ),
  "md": (
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06),
  ),
  "lg": (
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05),
  ),
  "xl": (
    0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
  ),
  "2xl": (
    0 25px 50px -12px rgba(0, 0, 0, 0.25),
  ),
  "inner": (
    inset 0 2px 4px 0 rgba(0, 0, 0, 0.06),
  ),
);

@function shadow($name...) {
  @return utils.property-get($-shadow-map, $name);
}

$-font-size-map: (
  "xs": 0.75rem,
  "sm": 0.875rem,
  "base": 1rem,
  "lg": 1.125rem,
  "xl": 1.25rem,
  "2xl": 1.5rem,
  "3xl": 1.875rem,
  "4xl": 2.25rem,
  "5xl": 3rem,
  "6xl": 3.75rem,
  "7xl": 4.5rem,
  "8xl": 6rem,
  "9xl": 8rem,
);

@function font-size($name) {
  @return utils.property-get($-font-size-map, $name);
}

$-font-weight-map: (
  "thin": 100,
  "extralight": 200,
  "light": 300,
  "normal": 400,
  "medium": 500,
  "semibold": 600,
  "bold": 700,
  "extrabold": 800,
  "black": 900,
);

@function font-weight($name) {
  @return utils.property-get($-font-weight-map, $name);
}

$-rounded-map: (
  "none": 0px,
  "sm": 0.125rem,
  "default": 0.25rem,
  "md": 0.375rem,
  "lg": 0.5rem,
  "xl": 0.75rem,
  "2-xl": 1rem,
  "3-xl": 1.5rem,
  "full": 9999px,
);

@function rounded($name...) {
  @return utils.property-get($-rounded-map, $name);
}

$-max-width-map: (
  "xs": 20rem,
  "sm": 24rem,
  "md": 28rem,
  "lg": 32rem,
  "xl": 36rem,
  "2xl": 42rem,
  "3xl": 48rem,
  "4xl": 56rem,
  "5xl": 64rem,
  "6xl": 72rem,
  "7xl": 80rem,
);

@function max-width($name) {
  @return utils.property-get($-max-width-map, $name);
}

$-spacing-map: (
  "0": 0px,
  "px": 1px,
  "0.5": 0.125rem,
  "1": 0.25rem,
  "1.5": 0.375rem,
  "2": 0.5rem,
  "2.5": 0.625rem,
  "3": 0.75rem,
  "3.5": 0.875rem,
  "4": 1rem,
  "5": 1.25rem,
  "6": 1.5rem,
  "7": 1.75rem,
  "8": 2rem,
  "9": 2.25rem,
  "10": 2.5rem,
  "11": 2.75rem,
  "12": 3rem,
  "14": 3.5rem,
  "16": 4rem,
  "20": 5rem,
  "24": 6rem,
  "28": 7rem,
  "32": 8rem,
  "36": 9rem,
  "40": 10rem,
  "44": 11rem,
  "48": 12rem,
  "52": 13rem,
  "56": 14rem,
  "60": 15rem,
  "64": 16rem,
  "72": 18rem,
  "80": 20rem,
  "96": 24rem,
);

@function spacing($name...) {
  @return utils.property-get($-spacing-map, $name);
}

@function sp($name...) {
  @return spacing($name...);
}

@function em($value) {
  @return utils.strip-unit($value) + 0em;
}

$-color-map: (
  "transparent": rgba(0, 0, 0, 0),
  "current": #000000,
  "black": #000000,
  "white": #ffffff,
  "gray-50": #f9fafb,
  "gray-100": #f3f4f6,
  "gray-200": #e5e7eb,
  "gray-300": #d1d5db,
  "gray-400": #9ca3af,
  "gray-500": #6b7280,
  "gray-600": #4b5563,
  "gray-700": #374151,
  "gray-800": #1f2937,
  "gray-900": #111827,
  "red-50": #fef2f2,
  "red-100": #fee2e2,
  "red-200": #fecaca,
  "red-300": #fca5a5,
  "red-400": #f87171,
  "red-500": #ef4444,
  "red-600": #dc2626,
  "red-700": #b91c1c,
  "red-800": #991b1b,
  "red-900": #7f1d1d,
  "yellow-50": #fffbeb,
  "yellow-100": #fef3c7,
  "yellow-200": #fde68a,
  "yellow-300": #fcd34d,
  "yellow-400": #fbbf24,
  "yellow-500": #f59e0b,
  "yellow-600": #d97706,
  "yellow-700": #b45309,
  "yellow-800": #92400e,
  "yellow-900": #78350f,
  "green-50": #ecfdf5,
  "green-100": #d1fae5,
  "green-200": #a7f3d0,
  "green-300": #6ee7b7,
  "green-400": #34d399,
  "green-500": #10b981,
  "green-600": #059669,
  "green-700": #047857,
  "green-800": #065f46,
  "green-900": #064e3b,
  "blue-50": #eff6ff,
  "blue-100": #dbeafe,
  "blue-200": #bfdbfe,
  "blue-300": #93c5fd,
  "blue-400": #60a5fa,
  "blue-500": #3b82f6,
  j "blue-600": #2563eb,
  "blue-700": #1d4ed8,
  "blue-800": #1e40af,
  "blue-900": #1e3a8a,
  "indigo-50": #eef2ff,
  "indigo-100": #e0e7ff,
  "indigo-200": #c7d2fe,
  "indigo-300": #a5b4fc,
  "indigo-400": #818cf8,
  "indigo-500": #6366f1,
  "indigo-600": #4f46e5,
  "indigo-700": #4338ca,
  "indigo-800": #3730a3,
  "indigo-900": #312e81,
  "purple-50": #f5f3ff,
  "purple-100": #ede9fe,
  "purple-200": #ddd6fe,
  "purple-300": #c4b5fd,
  "purple-400": #a78bfa,
  "purple-500": #8b5cf6,
  "purple-600": #7c3aed,
  "purple-700": #6d28d9,
  "purple-800": #5b21b6,
  "purple-900": #4c1d95,
  "pink-50": #fdf2f8,
  "pink-100": #fce7f3,
  "pink-200": #fbcfe8,
  "pink-300": #f9a8d4,
  "pink-400": #f472b6,
  "pink-500": #ec4899,
  "pink-600": #db2777,
  "pink-700": #be185d,
  "pink-800": #9d174d,
  "pink-900": #831843,
);

@function color($name...) {
  @return utils.property-get($-color-map, $name);
}

@function c($name...) {
  @return color($name...);
}
