.section {
  z-index: 0;
  position: relative;
  overflow: hidden;
  width: 100%;

  &__container {
    @include container($viewport-edge-padding);
    position: relative;
  }

  h2 {
    //font-weight: font-weight(bold);
    font-size: font-size(4xl);
    text-transform: uppercase;
    margin-top: 0;
  }

  #{&}__separator {
    padding: #{$header-height + sp(4)} 0;
  }
}
