@use "../utils/variables" as *;
@use "sass:math";

.grid {
  display: flex;
  flex-wrap: wrap;

  &--pad {
    margin: -#{em(sp(4))};
    .grid__item {
      padding: em(sp(4));
    }
  }

  .grid__item > *:first-of-type {
    padding-top: 0;
    margin-top: 0;
  }

  .grid__item > *:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

@mixin width($u, $l) {
  width: math.div($u, $l);
  width: calc(#{$u} / #{$l});
}

@mixin height($u, $l) {
  height: math.div($u, $l);
  height: calc(#{$u} / #{$l});
}
