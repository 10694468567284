p {
  margin: em(sp(2)) 0;
}

@mixin non-prose {
  img {
    display: block;
  }

  ul {
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

%non-prose {
  @include non-prose;
}

