.section--contact {
  font-size: font-size(base);

  @include screen(sm, font-size, font-size(2xl));

  .email {
    text-transform: uppercase;
    font-size: em(font-size(base));
  }

  .grid__item {
    width: 100%;

    @include screen(lg) {
      @include width(100%, 2);
    }
  }

  &__buttons {
    display: flex;
    font-size: em(font-size(xs));
    margin: -#{em(sp(1))};

    & > * {
      margin: em(sp(1));
    }

    &--nojs {
      display: none;
    }
  }
}

.email {
  user-select: none;
  word-wrap: break-word;

  &__at::before {
    content: "@";
  }

  &__dot::before {
    content: ".";
  }
}
