@charset 'utf-8';

@use "../../node_modules/modern-normalize/modern-normalize";
@use "sass:math";
@use "sass:color";

@use "utils/variables" as *;
@use "utils/mixins" as *;

@mixin font-face($family, $fname, $weight) {
  @font-face {
    font-family: $family;
    src: url("../fonts/#{$fname}.woff2") format("woff2"), url("../fonts/#{$fname}.woff") format("woff");
    font-weight: $weight;
    font-display: swap;
  }
}

//@include font-face("Cantarell", "Cantarell-Thin", 100);
@include font-face("Cantarell", "Cantarell-Light", 300);
@include font-face("Cantarell", "Cantarell-Regular", 400);
//@include font-face("Cantarell", "Cantarell-Bold", 700);
@include font-face("Cantarell", "Cantarell-ExtraBold", 800);

@import "./variables";

$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts/";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/variables";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/mixins";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/core";
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/fixed-width";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/icons";
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/screen-reader";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

body {
  font-family: font(sans);
  background: black;
  position: relative;
  color: c(gray, 100);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: font-weight(black);
}

::selection {
  background-color: white;
  color: black;
}

@import "./components/non-prose";
@import "./components/atomic";
@import "./components/icons";
@import "./components/section";
@import "./components/stack";
@import "./components/grid";
@import "./components/button";

@import "./header";
@import "./main";
@import "./band";
@import "./music";
@import "./concerts";
@import "./contact";
