.stack-bg {
  object-position: 50% 50%;
  object-fit: contain;
}

@mixin stack {
  position: absolute;
  top: 0;
  left: 0;
}

.stack {
  @include stack;
  display: block;
  --trX: 0;
  --trY: 0;
  transform: translate(var(--trX), var(--trY));

  &--h0 {
    left: 0;
  }

  &--h50 {
    left: 50%;
    --trX: -50%;
  }

  &--h100 {
    right: 0;
    left: auto;
  }

  &--v0 {
    top: 0;
  }

  &--v50 {
    top: 50%;
    --trY: -50%;
  }

  &--v100 {
    bottom: 0;
    top: auto;
  }
}
