.black {
  background-color: black;
}

.full {
  width: 100%;
  height: 100%;
}

.full-w {
  width: 100%;
}

.viewport {
  width: 100vw;
  height: 100vh;
}

.min-vh {
  min-height: 100vh;
}

.flex {
  display: flex !important;
}

.justify {
  text-align: justify;
}

.no-overflow {
  overflow: hidden;
}

.relative {
  position: relative;
}
